<!-- eslint-disable -->
<template>
  <v-app>
  </v-app>
</template>

<script>
/* eslint-disable */
  export default {
    name: 'DashboardIndex',
    beforeCreate() {
      localStorage.clear()
      this.$router.push({name:'Home'})
    }
  }
</script>
